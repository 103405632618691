import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";

const HowTORedeemPopup = () => {
  const { t } = useTranslation();

  return (
    <Popup title="HOW TO REDEEM" className="howtoredeem-us-popup">
      <ol>
        <p className="redeem-title">Steps to redeem:</p>
        <p dir="ltr">
          1. Scan the QR code printed on the offer pack or visit
          <br />
          <a href="www.gemini.bigcityexperience.com">
            www.gemini.bigcityexperience.com
          </a>{" "}
          to land on microsite
        </p>
        <p dir="ltr">
          2. Enter your name, mobile number state and batch code and click on
          submit
        </p>
        <p dir="ltr">3. Enter your OTP and click submit</p>
        <p dir="ltr">
          4. All particpants will win assured Ajio offer and also stand a chance
          to win cashback,gold coin or Activa
        </p>
      </ol>
    </Popup>
  );
};

export default HowTORedeemPopup;
