import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";
import "./index.scss";

const PrivacyPopup = () => {
  const { t } = useTranslation();

  return (
    <Popup title="Privacy Policy" className="privacy-us-popup">
      <p dir="ltr">
        By providing your personal information and by clicking ‘Submit’ you
        hereby confirm that you have read and understood the terms of Cargill’s
        Privacy Policy (Online Privacy Policy | Cargill) and consent to
        collection, storage, processing, transfer of your personal information
        as set out therein and for the purpose of participating in promotional
        and marketing activities and contests conducted and organized by
        Cargill.
      </p>
      <p dir="ltr">
        By clicking ‘Submit’ you also understand, consent and agree that your
        personal information will be shared by Cargill with its agency and
        partners involved in running the consumer contest and you have no
        objection to the same.
      </p>
    </Popup>
  );
};

export default PrivacyPopup;
