import { Dispatch } from "redux";
import { IpLookupData } from "../../interface/api";
import { CLEAR_IP_DETAILS, SET_IP_DETAILS } from "../types";

export const setIpDetails: any =
  (payload: IpLookupData) => async (dispatch: Dispatch) => {
    dispatch({
      type: SET_IP_DETAILS,
      payload,
    });
  };

export const clearIpDetails: any = () => async (dispatch: Dispatch) => {
  dispatch({
    type: CLEAR_IP_DETAILS,
  });
};
