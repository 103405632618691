import { useNavigate, useLocation } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout";
import React, { useEffect } from "react";
import { ROUTES } from "../../lib/consts";
import "./Completed.scss";
import tickimg from "../../assets/images/tickimg.png";
import { gtagTrackEvent, GA_EVENTS } from "../../lib/utils";
import footer from "../../assets/images/Bottom Creative.png";

import { useTranslation } from "react-i18next";

const Completed = () => {
  const { state } = useLocation();
  const { t } = useTranslation();

  {
    useEffect(() => {
      // Track the Thank You event when the component mounts
      gtagTrackEvent(GA_EVENTS.Thank_You);
    }, []);
  }
  const reward = localStorage.getItem("reward");

  return (
    <>
      <MainLayout className="completed-page">
        <div className="congratulation-container">
          {state?.newUser ? (
            <>
              <p className="text1"></p>
              <p className="text2"></p>
              <div className="note">
                <p className="text3"></p>
                <p className="text4"></p>
              </div>
            </>
          ) : (
            <>
              <div className="congratulation-img">
                {/*<img src={tickimg} />*/}
                <p className="thankyou-text">Congratulations!</p>
              </div>
              <p className="text12">
                You have won ₹200/- assured <br />
                {/*{reward}*/}
                Ajio reward
                <br />
                <p className="text13">{reward}</p>
                <br />
                Steps to redeem will be shared <br />
                to you via SMS.
                <br />
                <p className="yellow-text">
                  You also stand a chance to win <br />
                  Cashback, Gold coin and Activa. <br />
                  Check your Messages to see if you <br />
                  are a lucky winner.
                </p>
              </p>
            </>
          )}

          <img src={footer} className="footer-img" alt="footer" />
        </div>
      </MainLayout>
    </>
  );
};

export default Completed;
