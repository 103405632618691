import ContactUsPopup from "./ContactUsPopup";
import TermsPopup from "./TermsPopup";
import HowTORedeemPopup from "./HowToRedeemPopup";
import PrivacyPopup from "./Privacy";

const popups = {
  ContactUsPopup,
  TermsPopup,
  HowTORedeemPopup,
  PrivacyPopup,
};

export default popups;
