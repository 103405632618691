import MainLayout from "../../layouts/MainLayout";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import "./Register.scss";
import { useEffect, useRef, useState } from "react";
import API from "../../api";
import { ERROR_IDS } from "../../api/utils";
import { useNavigate, useLocation } from "react-router-dom";
import { LANGUAGE, LANGUAGES, ROUTES, STATES } from "../../lib/consts";
import store from "../../store/store";
import { setAccessToken } from "../../store/actions/authAction";
import { useTranslation } from "react-i18next";
import { MODAL_TYPES, useGlobalModalContext } from "../../helpers/GlobalModal";
import { useAuthentication } from "../../hooks/useAuthentication";
import {
  addCaptchaScript,
  hideCaptchaBadge,
  showCaptchaBadge,
} from "../../lib/utils";
import { setUserIdentification } from "../../store/actions/authAction";
import { gtagTrackEvent, GA_EVENTS } from "../../lib/utils";
import arrow from "../../assets/images/arrow.svg";
import batchcodeimg from "../../assets/images/batch-code.png";
import close from "../../assets/images/popclose.png";
import batchcodeimage from "../../assets/images/Batch Code Reference.png";
declare var grecaptcha: any;

type StateResponseProps = {
  state: string;
};

const hardcodedStates = [
  { state: "West Bengal" },
  { state: "Uttarakhand" },
  { state: "Uttar Pradesh" },
  { state: "Tripura" },
  { state: "Telangana" },

  { state: "Sikkim" },
  { state: "Rajasthan" },
  { state: "Punjab" },
  { state: "Puducherry" },
  { state: "Odisha" },
  { state: "Nagaland" },
  { state: "Mizoram" },
  { state: "Meghalaya" },
  { state: "Manipur" },
  { state: "Maharashtra" },
  { state: "Madhya Pradesh" },
  { state: "Lakshadweep" },
  { state: "Ladakh" },
  { state: "Kerala" },
  { state: "Karnataka" },
  { state: "Jharkhand" },
  { state: "Jammu and Kashmir" },
  { state: "Himachal Pradesh" },
  { state: "Haryana" },
  { state: "Gujarat" },
  { state: "Goa" },
  { state: "Delhi" },
  { state: "Dadra and Nagar Haveli and Daman and Diu" },
  { state: "Chhattisgarh" },
  { state: "Chandigarh" },
  { state: "Bihar" },
  { state: "Assam" },
  { state: "Arunachal Pradesh" },
  { state: "Andhra Pradesh" },
  { state: "Andaman and Nicobar Islands" },
  // Add more states as needed
];

const Register = () => {
  const [isAgreed, setIsAgreed] = useState(false);
  const [showError, setShowError] = useState(false);
  const [checkboxError, setCheckboxError] = useState("");
  const { showModal } = useGlobalModalContext();
  const [resendTimer, setResendTimer] = useState(60);
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [showPayoutForm, setShowPayoutForm] = useState(false);
  const [showStateDropdown, setShowStateDropdown] = useState(false);
  const [filteredCities, setFilteredCities] = useState<StateResponseProps[]>(
    []
  );
  const [filteredNewStates, setFilteredNewStates] = useState<
    StateResponseProps[]
  >([]);
  const [citiesFound, setCitiesFound] = useState(true);
  const [states, setStates] = useState("");
  const [selectedState, setSelectedState] = useState("");

  const [showPopup, setShowPopup] = useState(false);

  const handleClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleStateFieldClick = () => {
    setShowStateDropdown(!showStateDropdown);
  };

  const { isLoggedIn } = useAuthentication();
  const navigate = useNavigate();
  const location = useLocation();

  const timer = useRef<any | null>(null);

  const { t } = useTranslation();
  const handleCheckboxChange = () => {
    setIsAgreed(!isAgreed);
    setCheckboxError("");
  };

  useEffect(() => {
    setFilteredNewStates(filteredCities);
  }, [filteredCities]);

  useEffect(() => {
    // console.log(filteredCities);
    // console.log(filteredNewStates);
    {
      setFilteredCities(hardcodedStates);
    }
  }, []);

  useEffect(() => {
    // console.log("Show State Dropdown:", showStateDropdown);
  }, [showStateDropdown]);

  //State Dropdown:
  const handleStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.toLowerCase();

    const filtered = filteredCities.filter((state) =>
      state.state.toLowerCase().includes(inputValue)
    );

    setCitiesFound(filtered.length > 0);

    setFilteredNewStates(filtered);
    setShowStateDropdown(true);
  };

  const handleStateSelect = (selectedState: string) => {
    // console.log("modal clicke", selectedState);

    setStates(selectedState);
    setSelectedState(selectedState);
    // console.log("states", states);
    setShowStateDropdown(false);
  };

  const RegisterValidation = Yup.object().shape({
    name: Yup.string()
      .required(t("registerPage.errors.invalidName") || "Enter valid name")
      .matches(
        /^[a-zA-Z]{1}[a-zA-Z\s]{2,}$/,
        t("registerPage.errors.invalidName") || "Enter valid name"
      ),
    mobile: Yup.string()
      .required(
        t("registerPage.errors.invalidMobile") ||
          "*Please enter a valid 10-digit number"
      )
      .matches(
        /^[56789][0-9]{9}$/,
        t("registerPage.errors.invalidMobile") ||
          "*Please enter a valid 10-digit number"
      ),
    batchcode: Yup.string().required(
      t("registerPage.errors.invalidCode") ||
        "*That’s not a valid code. Please check and enter the correct code"
    ),
    state: Yup.string().required(
      t("registerPage.errors.invalidstate") || "*Please enter a valid State"
    ),

    agreeTnc: Yup.boolean().oneOf(
      [true],
      "Please accept the terms and conditions"
    ),
  });

  const OtpValidation = Yup.object().shape({
    otp: Yup.string()
      .required(
        t("registerPage.errors.invalidOtp") || "*Please enter valid OTP"
      )
      .matches(
        /^[0-9]{6}$/,
        t("registerPage.errors.incorrectOtp") ||
          "*You have entered an incorrect OTP. Please try again"
      ),
  });

  useEffect(() => {
    addCaptchaScript(() => {
      showCaptchaBadge();
    }); // Add this line to include the reCAPTCHA script
    return () => {
      hideCaptchaBadge(); // Add this line to hide the reCAPTCHA badge when the component is unmounted
    };
  }, []);

  const startTimer = () => {
    if (timer) clearInterval(timer.current);
    let time = 60;
    setResendTimer(time);
    timer.current = setInterval(() => {
      time--;
      setResendTimer(time);
      if (time <= 0) {
        clearInterval(timer.current);
      }
    }, 1000);
  };

  const resendOtp = () => {
    if (resendTimer <= 0) {
      startTimer();
      API.resendOTP().catch((err) => {
        console.log(err);
      });
    }
  };

  useEffect(
    () => () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    },
    []
  );

  function renderRegisterForm() {
    return (
      <p style={{color:"#000", fontSize:"1.5rem", padding:"1rem",flex:"1 1"}}>Thank you for your interest,<br/> this contest is now closed</p>

      // <Formik
      //   key="register-form"
      //   initialValues={{
      //     name: "",
      //     mobile: "",
      //     batchcode: "",
      //     email: "",
      //     state: states,
      //     agreeTnc: false,
      //   }}
      //   validationSchema={RegisterValidation}
      //   onSubmit={(values, errors) => {
      //     console.log(errors, values);
      //     // setShowOtpForm(true);
      //     if (values.agreeTnc) {
      //       API.register(values).then(() => {
      //         // gtagTrackEvent(GA_EVENTS.Registered);
      //         setShowOtpForm(true);
      //         startTimer();
      //       });
      //     } else {
      //       setCheckboxError("Please accept the terms and conditions");
      //       setShowError(true); // Optionally, show an error message
      //     }
      //     gtagTrackEvent(GA_EVENTS.Submit_click);
      //   }}
      // >
      //   {({
      //     values,
      //     handleChange,
      //     handleSubmit,
      //     handleBlur,
      //     errors,
      //     touched,
      //     setFieldValue,
      //   }) => {
      //     return (
      //       <Form
      //         onSubmit={handleSubmit}
      //         onClick={() => setShowStateDropdown(false)}
      //       >
      //         <div className="register-form">
      //           <p className="form-title ">{t("registerPage.title")}</p>
      //           <div className="input-group1">
      //             <input
      //               autoComplete="off"
      //               type="text"
      //               onChange={handleChange}
      //               value={values.batchcode}
      //               name="batchcode"
      //               maxLength={10}
      //               onBlur={handleBlur}
      //               placeholder={t("registerPage.placeholder.code")}
      //             />
      //             <img
      //               className="batchcode"
      //               src={batchcodeimg}
      //               alt="batchcode"
      //               onClick={handleClick}
      //             />
      //             {showPopup && (
      //               <div className="popup-container">
      //                 <div className="batch-popup">
      //                   <img
      //                     className="close"
      //                     src={close} // Add your close image URL here
      //                     alt="close"
      //                     onClick={handleClosePopup}
      //                   />
      //                   <img
      //                     className="popup-image"
      //                     src={batchcodeimage}
      //                     alt="batchcode"
      //                   />
      //                 </div>
      //               </div>
      //             )}
      //           </div>

      //           <div className="input-group">
      //             <input
      //               autoComplete="off"
      //               type="text"
      //               onChange={handleChange}
      //               value={values.name}
      //               name="name"
      //               onBlur={handleBlur}
      //               placeholder={t("registerPage.placeholder.name")}
      //             />
      //           </div>

      //           <div className="input-group">
      //             <input
      //               autoComplete="off"
      //               type="tel"
      //               onChange={handleChange}
      //               value={values.mobile}
      //               name="mobile"
      //               maxLength={10}
      //               onBlur={handleBlur}
      //               placeholder={t("registerPage.placeholder.mobile")}
      //               pattern="[0-9]*"
      //             />
      //           </div>
      //           <div className="input-group">
      //             <input
      //               autoComplete="off"
      //               type="email"
      //               onChange={handleChange}
      //               value={values.email}
      //               name="email"
      //               onBlur={handleBlur}
      //               placeholder={"Enter Email"}
      //             />
      //           </div>

      //           <div className="input-group">
      //             <select
      //               name="state"
      //               className="details-input"
      //               autoComplete="off"
      //               onBlur={handleBlur}
      //               onChange={handleChange}
      //               value={values.state}
      //               onClick={() => setShowStateDropdown(true)}
      //             >
      //               <option
      //                 value=""
      //                 label={t("registerPage.placeholder.state")}
      //                 disabled
      //               />
      //               {filteredNewStates.map((state) => (
      //                 <option key={state.state} value={state.state}>
      //                   {state.state}
      //                 </option>
      //               ))}
      //               {!filteredNewStates.length ? (
      //                 <option value="" className="nf">
      //                   Not Found
      //                 </option>
      //               ) : null}
      //             </select>
      //             <img className="arrow-icon" src={arrow} alt="Arrow" />
      //           </div>
      //           <p className="tamilnadu-text">
      //             This promotion is not applicable to the residents of Tamil
      //             Nadu!
      //           </p>

      //           {showStateDropdown && (
      //             <div className="city dropdown">
      //               <ul style={{ listStyleType: "none", padding: 0 }}>
      //                 {filteredNewStates.map((state) => (
      //                   <li
      //                     key={state.state}
      //                     onClick={() => {
      //                       handleStateSelect(state.state);
      //                       setFieldValue("state", state.state);
      //                       setShowStateDropdown(false);
      //                     }}
      //                   >
      //                     {state.state}
      //                   </li>
      //                 ))}
      //                 {!filteredNewStates.length ? (
      //                   <li className="nf" style={{ padding: "10px" }}>
      //                     Not Found
      //                   </li>
      //                 ) : null}
      //               </ul>
      //             </div>
      //           )}

      //           <div className="input-group flex-center1">
      //             <div className="checkbox-container1">
      //               <input
      //                 className="checkbox"
      //                 type="checkbox"
      //                 id="agreeTnc"
      //                 name="agreeTnc"
      //                 onChange={(e) => {
      //                   setFieldValue("agreeTnc", e.target.checked);
      //                   if (e.target.checked) {
      //                     showModal(MODAL_TYPES.PRVACY);
      //                   }
      //                 }}
      //               />
      //               <span>
      //                 {" "}
      //                 I agree to the{" "}
      //                 <span onClick={() => showModal(MODAL_TYPES.TERMS)}>
      //                   <u className="terms-link">Terms and Conditions {""}</u>
      //                 </span>
      //                 {" & "}
      //                 <a
      //                   href="https://www.cargill.com/privacy"
      //                   target="_blank"
      //                   rel="cargil"
      //                 >
      //                   <u className="terms-link">Privacy Policy</u>
      //                 </a>
      //               </span>
      //               <span className="checkmark"></span>
      //             </div>
      //           </div>
      //           {errors.batchcode && touched.batchcode && (
      //             <p className="error">{errors.batchcode}</p>
      //           )}
      //           {!errors.batchcode && errors.name && touched.name && (
      //             <p className="error">{errors.name}</p>
      //           )}
      //           {!errors.batchcode &&
      //             !errors.name &&
      //             errors.mobile &&
      //             touched.mobile && <p className="error">{errors.mobile}</p>}

      //           {!errors.batchcode &&
      //             !errors.name &&
      //             !errors.mobile &&
      //             errors.state &&
      //             touched.state && <p className="error">{errors.state}</p>}

      //           {!errors.batchcode &&
      //             !errors.name &&
      //             !errors.mobile &&
      //             !errors.state &&
      //             errors.agreeTnc &&
      //             touched.agreeTnc && (
      //               <p className="error">{errors.agreeTnc}</p>
      //             )}
      //         </div>
      //         <button className="btn btn-primary" type="submit">
      //           GET OTP
      //         </button>
      //         {/*<p className="coming-soon">COMING SOON</p>*/}
      //       </Form>
      //     );
      //   }}
      // </Formik>
    );
  }

  function renderOtpForm() {
    return (
      <Formik
        key="otp-form"
        initialValues={{
          otp: "",
        }}
        validationSchema={OtpValidation}
        onSubmit={(values, errors) => {
          // navigate(ROUTES.COMPLETED);

          grecaptcha
            .execute(process.env.REACT_APP_CAPTCHA_SITE_KEY, {
              action: "verify_otp",
            })
            .then((token: string) => {
              API.verifyOTP(values.otp, token)
                .then((response) => {
                  hideCaptchaBadge();
                  console.log("Access Token:", response.accessToken);
                  store.dispatch(setAccessToken(response.accessToken));
                  localStorage.setItem("reward", response.reward.toString());

                  gtagTrackEvent(GA_EVENTS.Verify_OTP);
                  if (response.fromPuducherry === true) {
                    navigate(ROUTES.THANK_YOU);
                  } else {
                    navigate(ROUTES.COMPLETED);
                  }
                })
                .catch((err) => {
                  const { messageId } = err;
                  switch (messageId) {
                    case ERROR_IDS.INVALID_OTP:
                      errors.setErrors({
                        otp:
                          t("registerPage.errors.invalidOtp") ||
                          "Please enter a valid OTP",
                      });
                      break;
                    default:
                      errors.setErrors({
                        otp: err.message,
                      });
                      break;
                  }
                });
            });
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          touched,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className="otp-form">
                <p className="form-title">{t("registerPage.verifyOtp")}</p>
                <div className="input-group">
                  <input
                    autoComplete="off"
                    type="tel"
                    onChange={handleChange}
                    value={values.otp}
                    name="otp"
                    maxLength={6}
                    onBlur={handleBlur}
                    placeholder={t("registerPage.placeholder.otp")}
                  />
                </div>
                {errors.otp && touched.otp && (
                  <p className="error">{errors.otp}</p>
                )}
                <div className="flex-center timer-wrapper">
                  {resendTimer > 0 && (
                    <div className="timer flex-center">{resendTimer}</div>
                  )}
                  <span>{t("registerPage.didNotReceive")}</span>
                  <span className="link" onClick={resendOtp}>
                    {t("registerPage.resend")}
                  </span>
                </div>
              </div>
              <button className="btn btn-primary" type="submit">
                {t("registerPage.verify")}
              </button>
            </Form>
          );
        }}
      </Formik>
    );
  }

  return (
    <MainLayout className="register-page">
      {showOtpForm ? renderOtpForm() : renderRegisterForm()}
    </MainLayout>
  );
};

export default Register;
