import { Route, Routes } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { ROUTES } from "./lib/consts";
// import Home from "./pages/Home/Home";
import Register from "./pages/Register/Register";
import Completed from "./pages/Completed/Completed,";
import { useEffect } from "react";
import API from "./api";
import { useGlobalLoaderContext } from "./helpers/GlobalLoader";
import store from "./store/store";
import { setUserIdentification } from "./store/actions/authAction";
import { addCaptchaScript, logoutUser } from "./lib/utils";
import { useAuthentication } from "./hooks/useAuthentication";
import PrivateRoute from "./helpers/PrivateRoute";
import TermsPage from "./pages/TermsPage";

function App() {
  const { showLoader, hideLoader } = useGlobalLoaderContext();
  const { isLoggedIn } = useAuthentication();

  {
    useEffect(() => {
      API.initialize(showLoader, hideLoader);
      API.createUser().then((response: any) => {
        // console.log(response);
        store.dispatch(setUserIdentification(response));
        if (!response.isLoggedIn && isLoggedIn) {
          logoutUser();
          toast.info("Your session has been expired");
        }
        // addCaptchaScript();
      });
    }, []);
  }

  return (
    <div className="App">
      <Routes>
        <Route path={ROUTES.REGISTER} element={<Register />} />
        <Route index element={<Register />} />
        <Route
          path={ROUTES.COMPLETED}
          element={
            <PrivateRoute>
              <Completed />
            </PrivateRoute>
          }
        />
        <Route path={ROUTES.TERMS_PAGE} element={<TermsPage />} />
      </Routes>
      <ToastContainer position="bottom-center" hideProgressBar={true} />
    </div>
  );
}

export default App;
