import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";
import "./index.scss";

const ContactUsPopup = () => {
  const { t } = useTranslation();

  return (
    <Popup title="Contact-us" className="contact-us-popup">
      {/*<p className="text1">FOR ANY QUERIES</p>
      <p className="text2">EMAIL US:</p>
      <p className="text3">
        <a href={"mailto:feedback@bigcity.in"}>feedback@bigcity.in</a>
      </p>
      <p className="text3">
        Premier Sales Promotions Pvt. Ltd, Mitra Towers, Bangalore - 560001
  </p>*/}

      <p dir="ltr">For any queries, please contact us at</p>
      <p dir="ltr">
        Premier Sales Promotions Pvt. Ltd, Mitra Towers, Bangalore - 560001
      </p>
      <p className="text3">
        <a href={"mailto:feedback@bigcity.in"}>feedback@bigcity.in</a>
      </p>
    </Popup>
  );
};

export default ContactUsPopup;
